.dropzone-container {
  background-color: #67676a;
  border-radius: 8px;
  padding: 12px;
  max-width: 250px;
  max-height: 250px;
  text-align: center;
  align-items: center;
  min-width: 145px;
  min-height: 117px;

  @include mobile {
    max-width: 100%;
  }

  .dropzone {
    width: 10%;
    height: 10%;
    min-height: 88.5px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  > div > img {
    max-height: 95px !important;
  }
  p {
    margin-bottom: 0px;
    font-weight: 500;
    letter-spacing: 0.25px;
    color: #ffffff;
  }

  &.multiple {
    max-width: inherit;
    max-height: inherit;
    padding: 8px;

    .div-multiples {
      display: flex;
      flex-wrap: wrap;

      .div-multiple {
        position: relative;
        display: flex;
        margin: 2px;
        background-color: #606062;

        .multiple-image {
          max-width: 250px;
          max-height: 250px;
          object-fit: contain;
        }

        .div-icons-multiple {
          position: absolute;
          top: 7px;
          right: 10px;

          .icon-multiple {
            color: $primary-color;
            margin: 2px;
            width: 16px;
            height: 16px;

            @include mobile {
              width: 20px;
              height: 20px;
            }

            &.delete {
              color: $error-color;
              background-color: transparent !important;
              margin: 2px !important;
            }
          }
        }
      }
    }
  }
}

.dropzone-close-button {
  color: white;
  position: absolute;
  top: 0px;
  right: 6px;
  cursor: pointer;
}

.dropzone-selected-image {
  max-width: 100px;
  max-height: 150px;
  align-self: center;
}
