.modal-company-checklist-tab-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  color: white;

  .modal-company-checklist-tab-create-container {
    display: flex;
    gap: 16px;
    align-items: end;
    width: 100%;
  }
}
