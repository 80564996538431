.remove-margin {
  margin: 0 !important;
}

.os-notes-container {
  margin-top: 24px;
  margin-bottom: 16px;
  min-height: 70vh;

  .os-notes-textarea {
    min-height: 50vh !important;
    text-transform: none;
  }

  .os-notes-tabs-container {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .os-notes-button-tabs {
    text-align: center;
    width: 100%;
    color: white;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .os-notes-button-tabs:hover {
      background-color: red;
    }
  }
  .os-notes-button-tabs-enabled {
    background-color: #808080;
  }
  .os-notes-button-tabs-disabled {
    background-color: #66604c;
  }
}

.os-entry-checklist-title {
  color: white;
}
.os-entry-checklist-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.os-entry-checklist-actions-button-container {
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  height: 40px;
}

.os-entry-checklist-register-inputs-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.os-entry-checklist-textarea {
  min-height: 20vh !important;
  text-transform: none;
}

.os-entry-checklist-savebutton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.os-entry-checklist-cancelbutton {
  width: 100%;
  background-color: inherit;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.os-entry-checklist-image-container {
  display: flex;
  flex-direction: column;
  background-color: inherit;
  padding: 6px;
  border: white 1px solid;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  position: relative;
  width: 100%;
}

.os-entry-checklist-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 8px;
}

.os-entry-checklist-image-description {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 4px;
}

.os-entry-checklist-image-mapper {
  display: flex;
  gap: 10px;
  margin-top: 16px;
  flex-wrap: wrap;
  overflow-y: auto;
}

.os-entry-checklist-image-actions {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.os-entry-checklist-list-icon-style {
  color: #7bdd68;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.os-entry-checklist-fixed-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: white;
  margin-top: 16px;

  .os-entry-checklist-fixed-container-title {
    font-size: 18px;
    font-weight: 500;
  }

  .os-entry-checklist-fixed-container-item {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .os-entry-checklist-fixed-container-item-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .os-entry-checklist-fixed-title {
    color: white;
    max-width: 400px;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Define o número máximo de linhas */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    white-space: normal;
  }
}

.os-details-grid-date-fields {
  grid-template-columns: 1fr 1fr;
  display: grid;
  gap: 10px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Define o número máximo de linhas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  white-space: normal;
}

.entry-os-image {
  max-height: 150px;
  max-width: 145px;
  background-size: cover;
  min-width: 145px;
  min-height: 117px;
  border-radius: 6px;
  cursor: pointer;
}
