.budget-tables-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.pt-20px {
  padding-top: 20px;
}

.tables-tabs-wrapper {
  display: flex;
  padding-top: 16px;
  justify-content: center;
  gap: 4px;
  padding-bottom: 16px;
}

.full-screen-tabs-container {
  display: flex;
  gap: 4px;
  justify-content: center;
  padding-top: 10px;
}

.budget-tables-borders {
  border: 1px solid #ffffff50;
  border-radius: 6px;
  min-width: 49%;
  padding: 6px;
}

.action-button {
  display: inline-flex;
  height: 40px;
  max-width: 50px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.button-wrapper {
  :hover {
    opacity: 0.5;
  }
}

.container-modal-create-part {
  width: 100%;
}

.modal-budget-nav-tabs-container {
  display: flex;
  justify-content: center;
  gap:6px;
  flex-wrap: wrap;
}