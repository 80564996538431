.custom-button {
  width: auto;
  padding: 5px 10px;
  background-color: #4b4b4d;
  color: #fff;
  border: 2px solid;
  border-color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &.highlighted {
    background-color: #4b4b4d;
    border-color: #62c300;
    color: #62c300;
  }

  @include mobile {
    padding: 3px 5px;
  }

  &.selector-modal {
    @include mobile {
      font-size: 12px;
    }
  }
}
